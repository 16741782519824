import { Nav } from "react-bootstrap";
import menu from 'data/menu';
import SidebarFooter from "components/SidebarFooter/SidebarFooter";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import SidebarHeading from "components/SidebarHeading/SidebarHeading";
import SidebarLink from "components/SidebarLink/SidebarLink";

const Sidebar = () => {
    return ( 
        <>
            <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                <div className="sb-sidenav-menu">
                    <Nav as="nav">
                        {menu.map(heading => (
                            <div key={`Nav-${heading.name}`}>
                                <SidebarHeading name={heading.name} key={heading.name}/>
                                {heading.children.map(link => (
                                    <SidebarLink to={link.to} name={link.name} icon={link.icon} key={link.name} children={link.children}/>
                                ))}
                            </div>
                        ))}
                    </Nav>
                </div>
                <SidebarFooter/>
            </nav>
        </>
    );
}
 
export default Sidebar;