import { Breadcrumb  } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import UserContext from 'contexts/UserContext';
import UserPageTemplate from 'templates/UserPageTemplate';

const ProfilView = () => {
    return (
        <UserPageTemplate>
            <>
                <h1 className='mt-4'>Profil</h1>
                <Breadcrumb className='mb-4'>
                    <Breadcrumb.Item linkAs={NavLink} linkProps={{ to:'/home'}}>Strona główna</Breadcrumb.Item>
                    <Breadcrumb.Item linkAs={NavLink} linkProps={{ to:'/profil'}} active>Profil</Breadcrumb.Item>
                </Breadcrumb>
                <UserContext.Consumer>
                    {context => (
                    <p>This is {JSON.stringify(context)}</p>   
                    )}
                </UserContext.Consumer>
                <AppContext.Consumer>
                    {context => (
                    <p>This is {JSON.stringify(context)}</p>   
                    )}
                </AppContext.Consumer>
            </>
        </UserPageTemplate>
    );
}
 
export default ProfilView;