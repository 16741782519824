import React, { useEffect, useState } from 'react';
import { Card, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import PropTypes from 'prop-types';
// import useFetch from "hook/useFetch";
import api from 'services/api';

export default function Termometr({ name, ...props }) {
    const [isPending, setIsPending] = useState(true);
    const [isFirst, setIsFirst] = useState(true);
    const [error, setError] = useState(null);
    const [blink, setBlink] = useState(false);
    const [pomiar, setPomiar] = useState([{wynik: 'XXXX', min: 'XX', max: 'XX', status: 'secondary', id: 0, name: 'Nazwa', jednostka: ''}]);
    // const {data:blogs,isPending,error} = useFetch('http://localhost:8080/termometr/'+props.url);

    useEffect(() => {

        const fetchTemperatura =() =>{
            setIsPending(true);
            const abortCont = new AbortController();
            api(process.env.REACT_APP_URL_TERMOMETRY + 'thermo/' + props.id + '/value', {signal: abortCont.signal })
            .then((response) => { 
                if (response.status !== 200) {
                    throw Error('could not fetch the data for that resource')
                }
                return response.data;
            })
            .then((data) => {
                setBlink(false);
                let wyniki = [];
                let pomiary = data;
                for (var i = 0; i < pomiary.length; i++) {
                    let wynik = pomiary[i].val;
                    let max = pomiary[i].max;
                    let min = pomiary[i].min;
                    let id = pomiary[i].id;
                    let name = pomiary[i].name ?? 'Nazwa';
                    let color = 'secondary';
                    if (max >= wynik && wynik >= min) {
                        color = 'success';
                    }else if(max < wynik) {
                        color = 'danger';
                        setBlink(true);
                    }else if(min > wynik) {
                        color = 'info';
                        setBlink(true);
                    }else {
                        color = 'secondary';
                        setBlink(true);
                    }
                    let temp = {wynik: wynik, min: min, max: max, status: color, id: id, name: name, jednostka: pomiary[i].unit}
                    wyniki.push(temp)
                }   
                setPomiar(wyniki);
                setError(null);
            })
            .catch(err => {
                if (err?.name === 'AbortError') {
                    console.log('fetch aborted')
                }else{
                    setPomiar([])
                    setBlink(true);
                    setError(err.message);
                }
            })
            .finally(() => {
                setIsPending(false);
            })
            return () => abortCont.abort();
        }

        const interval = setInterval(() => {
            if (!isPending || isFirst) {
                if (isFirst) {
                    setIsFirst(false)
                }
                fetchTemperatura()
            }
            // setInterval(fetchTemperatura(), 5000)
        }, 5000);
        return () => clearInterval(interval);
    }, [props,isPending,isFirst])
    const renderHTML = (escapedHTML) => React.createElement("span", { dangerouslySetInnerHTML: { __html: escapedHTML } });
    
  return(
        <OverlayTrigger
            placement='bottom'
            overlay={
            <Tooltip>
                Lokalizacja: <strong>{props.location}</strong>.
            </Tooltip>
            }
        >
            <Card className='h-100' bg='light'>
                <Card.Header className='hstack'>{blink && <Spinner animation="grow" variant="danger" size="sm" className='me-2'/>}{name}{isPending && <Spinner animation="border" size="sm" className='ms-auto'/>}</Card.Header>
                {error !== null
                    ? 
                    <Card.Body className='list-group-item-warning'>
                        <Card.Text as='div' className='d-flex justify-content-around align-items-center'>
                            <div className='d-block'>{error}</div> 
                        </Card.Text>
                    </Card.Body>
                    : 
                    pomiar.map(item => (
                        <Card.Body key={item.id}className={`list-group-item-${item.status}`}>
                            {pomiar.length !== 1 ? <Card.Subtitle>{item.name}</Card.Subtitle> : '' }
                            <Card.Text as='div' className='d-flex justify-content-around align-items-center'>
                                <div className="fs-2">
                                    {item.wynik}{renderHTML(item.jednostka) }
                                </div>
                                <div className="fw-bold">
                                    <span className={item.status === 'info-stop' ? 'text-dark d-block' : 'text-info d-block'}>MIN: {item.min}{renderHTML(item.jednostka)}</span>
                                    <span className={item.status === 'danger-stop' ? 'text-dark d-block' : 'text-danger d-block'}>MAX: {item.max}{renderHTML(item.jednostka)}</span>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    ))
                }
                <Card.Footer className='d-flex align-items-center justify-content-between'>
                    <a href={`http://${props.url}`} target="_blank" rel="noopener noreferrer" className='small stretched-link'>Zarządzanie</a>
                    <div className="small">
                        <FontAwesomeIcon icon={solid('angle-right')} />
                    </div>
                </Card.Footer>
            </Card>
        </OverlayTrigger>
  )
}

Termometr.propTypes = {
  name: PropTypes.string.isRequired,
}