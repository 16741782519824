// import Login from 'components/Login/Login';
import LoginComponent from 'components/LoginComponent/LoginComponent'
import {Col, Row } from 'react-bootstrap';
import OnePageTemplate from 'templates/OnePageTemplate';

const LoginView = ({ ...props }) => {
    return ( 
        <OnePageTemplate>
            <Row className="justify-content-md-center">
                <Col lg='5'>
                    {/* <Login { ...props } /> */}
                    <LoginComponent { ...props } />
                </Col>
            </Row>
        </OnePageTemplate>
    );
}
 
export default LoginView;