import React from 'react';
import packageJson from '../../package.json';

const AppContext = React.createContext({
    app: {
        title: `${packageJson.name.charAt(0).toUpperCase()}${packageJson.name.slice(1)}`,
        version: `${packageJson.version}`,
        author: `${packageJson.author}`,
        year: 2022,
    }
});

export default AppContext;