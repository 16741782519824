import { useEffect, useState } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
// import useFetch from "hook/useFetch";
import UserPageTemplate from 'templates/UserPageTemplate';
import Termometr from 'components/Termometr/Termometr';
import Loader from 'components/Loader/Loader'
import api from 'services/api';

const TermometryView = () => {
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(false);
    const [termometry, setTermometry] = useState([]);

    useEffect(() => {
        const abortCont = new AbortController();
        api(process.env.REACT_APP_URL_TERMOMETRY + 'thermo', {signal: abortCont.signal})
        .then(res => {
            // if (res.statusText !== 'OK') {
            if (res.status !== 200) {
                throw Error('could not fetch the data for that resource')
            }
          return res.data;
        })
        .then(data => {
            setTermometry(data);
            setError(null);
        })
        .catch(err => {
            if (err.name === 'AbortError') {
                console.log('fetch aborted')
            }else{
                setError(err.message);
            }
        })
        .finally(() => {
            setIsPending(false);
        })
        return () => abortCont.abort();
    }, [])

    return (
        <UserPageTemplate>
            <>
                {isPending && <Loader/> }
                {error && 
                <Alert variant="danger">
                    <Alert.Heading>Błąd</Alert.Heading>
                    <p>
                    {error}
                    </p>
                </Alert>}
                <Row xs={1} sm={3} md={4} xl={6} className="g-4">
                    { termometry && (
                        termometry.map(item => (
                            <Col key={item.id} >
                                <Termometr name={item.name} {...item} />
                            </Col>
                        ))
                    )}
                </Row>
            </>
        </UserPageTemplate>
    );
}
 
export default TermometryView;