import { Container } from 'react-bootstrap';

const OnePageTemplate = ({children}) => {
    return ( 
        <div className="bg-dark">
          <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
              <main>
                <Container>
                    {children}
                </Container>
              </main>
            </div>
          </div>
        </div>
    );
}
 
export default OnePageTemplate;