import { useContext } from "react";
import { Navbar, Button, Dropdown } from "react-bootstrap";
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import AppContext from 'contexts/AppContext';
import UserContext from 'contexts/UserContext';
import AuthService from "services/authService";

const Topbar = () => {
    const user = useContext(UserContext);

    const sidebarToggle = () => {
        document.body.classList.toggle('sb-sidenav-toggled');
        localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
    }
    const logout = () => {
        // localStorage.removeItem('token');
        AuthService.logout();
        window.location.replace("/");
    }

    return ( 
        <>
            <Navbar bg="dark" variant="dark" sticky="top" className="sb-topnav navbar navbar-expand navbar-dark">
                <Navbar.Brand as={Link} to="/" className="ps-3">
                <AppContext.Consumer>
                    {context => (
                        <>
                            {context.app.title} ({context.app.version})
                        </>
                    )}
                </AppContext.Consumer>
                </Navbar.Brand>
                <Button variant="link" size="sm" id="sidebarToggle" className="order-1 order-lg-0 me-4 me-lg-0" onClick={sidebarToggle}><FontAwesomeIcon icon={solid('bars')} /></Button>
                <Dropdown className="navbar-nav ms-auto me-3 me-lg-4" as="ul">
                    <Dropdown.Toggle id="dropdown-basic" className="nav-link" as="li">
                        <FontAwesomeIcon icon={solid('user')} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu as="ul" className="dropdown-menu-end">
                        <Dropdown.Item to="/profil" as={NavLink}>{user.name}</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item href="#" as="li" onClick={logout} role="button">Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Navbar>
        </>
     );
}
 
export default Topbar;