import { Container } from 'react-bootstrap';
import Topbar from 'components/Topbar/Topbar';
import Sidebar from 'components/Sidebar/Sidebar';
import Footer from 'components/Footer/Footer';

const UserPageTemplate = ({children}) => {
    return ( 
        <div className="sb-nav-fixed">
          <Topbar/>
          <div id="layoutSidenav">
            <div id="layoutSidenav_nav">
              <Sidebar />
            </div>
            <div id="layoutSidenav_content">
              <main>
                <Container fluid className="px-4 mt-4">
                    {children}
                </Container>
              </main>
              <Footer/>
            </div>
          </div>
        </div>
    );
}
 
export default UserPageTemplate;