import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import useToken from 'hook/useToken';
import AuthService from "services/authService";
// import AuthVerify from "services/authVerify";
import EventBus from "services/eventBus";
import UserContext from 'contexts/UserContext';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import HomeView from 'views/HomeView/HomeView';
import LoginView from 'views/LoginView/LoginView';
import ErrorView from 'views/ErrorView/ErrorView';
import AppContext from 'contexts/AppContext';
import TermometryView from 'views/TermometryView/TermometryView';
import ProfilView from "views/ProfilView/ProfilView";

library.add(fas,far)

document.title = `${AppContext._currentValue.app.title} (${AppContext._currentValue.app.version})`;
console.log(AppContext._currentValue.app.title + ' ' + AppContext._currentValue.app.version)

function App() {
  const [currentUser, setCurrentUser] = useState(undefined);
  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
    }

    EventBus.on("logout", () => {
      AuthService.logout();
    });

    return () => {
      EventBus.remove("logout");
    };

  }, []);

  if (!currentUser) {
    return <LoginView />
  }

  return (
    <UserContext.Provider value={currentUser}>
        <Router>
          <Routes>
            <Route path="/" element={<HomeView />} />
            <Route path="/home" element={<Navigate to="/"/>} />
            <Route path="/profil" element={<ProfilView />} />
            <Route path="/termometry" element={<TermometryView />} />
            {/* <Route path="/login" element={<LoginView />} /> */}
            <Route path="/error/:id" element={<ErrorView />} />
            <Route path="*" element={<Navigate to="/error/404"/>} />
          </Routes>
          {/* <AuthVerify logOut={AuthService.logout()}/> */}
        </Router>
    </UserContext.Provider>
  );
}

export default App;
