import { Component } from "react";
import { FloatingLabel, Button, Card, Form } from 'react-bootstrap';
import authService from "services/authService";
import MySwal from 'services/mySwal'

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.state = {
      username: "",
      password: "",
      loading: false,
      message: ""
    };
  }
  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }
  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }
  handleLogin(e) {
    e.preventDefault();
    MySwal.fire({
      title: 'Ładowanie danych',
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading()
      },
    });
    let errors = [];
    this.setState({
      message: "",
      loading: true
    });
    // this.form.validateAll();
    if (errors.length === 0) {
      authService.login(this.state.username, this.state.password).then(
        () => {
        //   this.props.history.push("/");
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            MySwal.fire({
              title: 'Błąd',
              text: resMessage,
              icon: 'error',
              allowOutsideClick: false,
              didOpen: () => {
                MySwal.hideLoading()
              },
            });
          
          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }
  render() {
    return (
        <Card className="shadow-lg border-0 rounded-lg mt-5">
            <Form onSubmit={this.handleLogin}
                ref={c => {
                this.form = c;
                }}>
                <Card.Header><h3 className="text-center font-weight-light my-4">Login</h3></Card.Header>
                <Card.Body>
                    {/* {this.state.message && <Card.Title>{this.state.message}</Card.Title>}
                    {this.state.loading && <Card.Subtitle>Loadnig...</Card.Subtitle>} */}
                    <Card.Text as='div'>
                        <FloatingLabel controlId="floatingInput" label="Login" className="mb-3">
                            <Form.Control type="text" placeholder="Login" name="username" value={this.state.username} onChange={this.onChangeUsername} required/>
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingPassword" label="Password" className="mb-3">
                            <Form.Control type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.onChangePassword} required/>
                        </FloatingLabel>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox" hidden>
                            <Form.Check type="checkbox" label="Remember Password" />
                        </Form.Group>
                    </Card.Text>
                </Card.Body>
                <Card.Footer className="d-grid">
                    <Button variant="primary" type="submit" disabled={this.state.loading}>
                        Login
                    </Button>
                </Card.Footer>
            </Form>
        </Card>
    );
  }
}