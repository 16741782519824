import { useParams, NavLink } from "react-router-dom"
import { Card, Col, Form, Row } from 'react-bootstrap';
import OnePageTemplate from 'templates/OnePageTemplate';

const ErrorView = () => {
    const { id } = useParams()

    return ( 
        <OnePageTemplate>
            <Row className="justify-content-md-center">
                <Col lg='5'>
                    <Card className="shadow-lg border-0 rounded-lg mt-5">
                        <Form>
                            <Card.Header><h3 className="text-center font-weight-light my-4">{id}</h3></Card.Header>
                            <Card.Body>
                                <Card.Title>Sorry</Card.Title>
                                <Card.Text>
                                    That page cannot be found
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="d-grid">
                                <NavLink to="/" className="btn btn-primary">Back to the homepage...</NavLink>
                            </Card.Footer>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </OnePageTemplate>
    );
}
 
export default ErrorView;