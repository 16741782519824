import AppContext from 'contexts/AppContext';

const Footer = () => {
    const dzis = new Date();

    return ( <>
        <footer className="py-4 bg-light mt-auto">
            <div className="container-fluid px-4">
                <div className="d-flex align-items-center justify-content-between small">
                    <AppContext.Consumer>
                        {context => (
                            <div className="text-muted">Copyright &copy; {context.app.author} {dzis.getFullYear()===context.app.year ? dzis.getFullYear() : `${context.app.year} - ${dzis.getFullYear()}`}</div>   
                        )}
                    </AppContext.Consumer>
                </div>
            </div>
        </footer>
    </> );
}
 
export default Footer;