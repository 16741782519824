const menu = [
    {
        name: 'Czujniki',
        children: [{
            name: 'Termometry',
            to: '/termometry',
            icon: 'fa-temperature-high'
        }]
    }
]

export default menu