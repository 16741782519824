import { Row, Col, Card, Breadcrumb  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { NavLink } from 'react-router-dom';
import UserPageTemplate from 'templates/UserPageTemplate';

const HomeView = () => {
    return (
        <UserPageTemplate>
            <>
                <h1>Strona główna</h1>
                <Breadcrumb className='mb-4'>
                    <Breadcrumb.Item linkAs={NavLink} linkProps={{ to:'/home'}} active>Strona główna</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col md="6" xl="3">
                        <Card>
                            <Card.Header>Termometry</Card.Header>
                            <Card.Body>
                                <Card.Title>Termometry</Card.Title>
                                <Card.Text>
                                    Monitoring temperatur
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className='d-flex align-items-center justify-content-between'>
                                <NavLink to="termometry" className='small stretched-link'>Wyświetl</NavLink>
                                <div className="small">
                                <FontAwesomeIcon icon={solid('angle-right')} />
                                </div>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </>
        </UserPageTemplate>
    );
}
 
export default HomeView;