import PropTypes from 'prop-types';

const SidebarHeading = (props) => {
    return ( <>
        <div className="sb-sidenav-menu-heading">{props.name}</div>
    </> );
}

SidebarHeading.propTypes = {
    name: PropTypes.string.isRequired,
}
export default SidebarHeading;