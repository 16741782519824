import { useContext } from "react";
import UserContext from 'contexts/UserContext';
const SidebarFooter = () => {
    const MyContext = useContext(UserContext);
    return ( <>
        <div className="sb-sidenav-footer">
            <div className="small">Zalogowany:</div>
            {MyContext.name}
        </div>
    </> );
}
 
export default SidebarFooter;