import { useState } from "react";
import { Nav, Collapse } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SidebarLink = (props) => {
    const [open, setOpen] = useState(false);

    return ( <div key={`Link-${props.name}`}>
        <Nav.Link as={NavLink} to={props.to} onClick={(event) => {setOpen(!open); props.children && event.preventDefault();}} className={ (props.children && !open) && 'collapsed'} >
            <div className="sb-nav-link-icon">
             <FontAwesomeIcon icon={`fa-solid ${props.icon}`} fixedWidth/>
            </div>
            {props.name}
            {
                props.children && 
                <div className="sb-sidenav-collapse-arrow">
                    <FontAwesomeIcon icon={`fa-solid fa-angle-down`} fixedWidth/>
                </div>
            }
        </Nav.Link>
        {
            props.children && 
            <Collapse in={open}>
                <Nav as="nav" className="sb-sidenav-menu-nested">
                    {props.children.map(link => (
                        <div key={`SidebarLink-${link.name}`}> 
                            <SidebarLink to={link.to} name={link.name} icon={link.icon} key={link.name} children={link.children}/>
                        </div>
                    ))}
                </Nav>
            </Collapse>
        }
    </div> );
}

SidebarLink.propTypes = {
    to: PropTypes.string,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
}

SidebarLink.defaultProps = {
    to: '/',
    icon: 'fa-circle-chevron-right',
}

export default SidebarLink;